import React, { useEffect, useRef, useState } from "react"
import "./new-rcf-style.css"
// import "swiper/css"
// import "swiper/css/navigation"
// import "../../Sliders/sliders.scss"
import PlusImage from "../../../asst/Plus-more-pics.svg"
import imgShareUpload from "../../../asst/ImageShareUpload.svg"
import Cancel from "../../../asst/Cancel.svg"
import {
  encryptData,
  getImageUrl,
  getThumbnailImageUrl,
  link,
  uploadToS3,
} from "../../../helper"
import ApiManager from "../../../classes/ApiManager"
import { useSearchParams } from "react-router-dom"
import NewTellUsStarter from "./NewTellUsStarted"
import NewRatePopup from "./NewRatePopup"
import ReviewImage from "../../../images/review-popup-image.png"
import NewThatsAll from "./NewThatsAll"
import NewReviewSubSimple from "./NewReviewSubSimple"
import SectionLoader from "./SectionLoader"
import { CrossIcon } from "../../../Svgs/Svg"
import OfferDiscount from "./OfferDiscount"

export default function NewRcf() {
  const apiManager = ApiManager.getInstance()

  const [searchParams, setSearchParams] = useSearchParams()
  apiManager._setXToken(searchParams.get("merchantId"))
  let storeCurrency = searchParams.get("storeCurrency")
  let offside = searchParams.get("offside")
  let orderNo = searchParams.get("orderNo")
  let productTitle = searchParams.get("productName")
  let productId = searchParams.get("productId")
  let productImage = searchParams.get("productImage")

  console.log(orderNo, "==>orderNo")

  const [currentComponentIndex, setCurrentComponentIndex] = useState(0)
  const [error, setError] = useState(true)
  const [attributeValue, setAttribute] = useState([])
  const [requiredAttributes, setRequiredAttributes] = useState(false)
  const [data, setData] = useState({})
  const [isTyped, setisTyped] = useState(false)
  const [starCheck, setStarCheck] = useState(false)
  const [starReview, setReviewCheck] = useState(false)
  const [answer, setAnswer] = useState(3)
  const [showDetail, setShowDetail] = useState(false)

  const [attributeAnswerValue, setAttributeAnswerValue] = useState(true)
  const [attributeAnswerMultipleValue, setAttributeAnswerMultipleValue] =
    useState(true)
  const [settings, setSettings] = useState({})
  const [collectSettings, setCollectSettings] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [pageLoader, setPageLoader] = useState(true)
  const [showLoaderSub, setShowLoaderSub] = useState(true)
  const [errorAttribute, setErrorAttribute] = useState(false)
  let rcfvalue = searchParams.get("rcf") === "true"

  // const [alreadyAdded, setAlreadyAdded] = useState(false)
  const [files, setFiles] = useState([])
  const [codeData, setCodeData] = useState(null)
  const [attributes, setAttributes] = useState([])
  // const [displayReviewData, setDisplayReviewData] = useState([])

  const handleContinueClick = () => {
    setCurrentComponentIndex(currentComponentIndex + 1)
    window.scrollTo({ top: 0 })
  }
  const handleBack = () => {
    setCurrentComponentIndex(currentComponentIndex - 1)
    window.scrollTo({ top: 0 })
  }
  // function getQueryString() {
  //   var queryStringKeyValue = window.parent.location.search.replace('?', '').split('&');
  //   var qsJsonObject = {};
  //   if (queryStringKeyValue != '') {
  //     for (let i = 0; i < queryStringKeyValue.length; i++) {
  //       qsJsonObject[queryStringKeyValue[i].split('=')[0]] = queryStringKeyValue[i].split('=')[1];
  //     }
  //   }
  //   return qsJsonObject;
  // }

  // const [locationName, setLocationName] = useState({
  //   name: "",
  //   latitude: "",
  //   longitude: "",
  // })
  const [photoRcf, setPhotoRcf] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [ordersData, setOrdersData] = useState({})

  const togglePhotoReview = () => {
    setPhotoRcf(!true)
  }

  let photoReviews = collectSettings?.photoReviews?.show ?? true
  let videoReviews = collectSettings?.videoReviews?.show ?? true
  const [fName, setFName] = useState(ordersData?.[0]?.customerName ?? "")
  const [lName, setLName] = useState("")
  const [email, setEmail] = useState("")
  const [isTyped1, setIsTyped1] = useState(ordersData.length > 0 ? true : false)
  const [isTyped2, setIsTyped2] = useState(ordersData.length > 0 ? true : false)
  const [isTyped3, setIsTyped3] = useState(ordersData.length > 0 ? true : false)

  console.log(photoReviews, "mylengthPhoto")
  useEffect(() => {
    console.log("last data", data)
    console.log(
      "last datass",
      ordersData?.[0]?.customerName ?? false,
      ordersData?.[0]?.customerName ? true : false,
      isTyped1
    )
  }, [data, ordersData, isTyped1])
  // React.useEffect(() => {
  //   const receiveMessage = (event) => {
  //     rcfvalue = event.data // Access the rcf value sent from parent
  //     // Use the received value here
  //     console.log("Received rcf value:", rcfvalue)
  //   }

  //   window.addEventListener("message", receiveMessage)

  //   return () => {
  //     // Clean up event listener when the component unmounts
  //     window.removeEventListener("message", receiveMessage)
  //   }
  // }, [])

  // const [locationError, setLocationError] = useState(null)
  // const getLocation = async () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const latitude = position.coords.latitude
  //         const longitude = position.coords.longitude

  //         // Fetch location name based on latitude and longitude
  //         try {
  //           const response = await fetch(
  //             `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=pk.eyJ1IjoibnVtYW5pcWJhbCIsImEiOiJjbHQ4d3U2a3owMzJ5MnFvYmttMHQzMXB3In0.BI8gpBPZIEP8jbUXMb6Peg`
  //           )
  //           console.log("responseMylocation", response)
  //           if (!response.ok) {
  //             throw new Error("Failed to fetch location name")
  //           }
  //           const data = await response.json()
  //           console.log(data, "=====dataa")
  //           setLocationName({
  //             name: data?.features[1]?.place_name,
  //             latitude: latitude,
  //             longitude: longitude,
  //           })
  //         } catch (error) {
  //           console.error("Error fetching location name:", error)
  //           setLocationError(error.message)
  //         }
  //       },
  //       (error) => {
  //         console.error("Error getting location:", error)
  //         setLocationError("Error getting location: " + error.message)
  //       }
  //     )
  //   } else {
  //     setLocationError("Geolocation is not supported by this browser.")
  //   }
  // }

  // useEffect(() => {
  //   getLocation()
  // }, [])

  // console.log(ordersData?.products[orderProductIndex - 1]?.id, "idUser")

  const submit = async (userData) => {
    setShowLoader(true)
    console.log("submit1231231231111", userData)
    console.log("submit1231231231111", data)

    let body = {
      productID:
        ordersData[0]?.products[orderProductIndex - 1]?.id ??
        searchParams.get("productId"),
      merchantId: searchParams.get("merchantId"),

      productImage:
        ordersData[0]?.products[orderProductIndex - 1]?.image ??
        searchParams.get("productImage"),
      reviewRating: data?.review,
      reviewTitle: data?.title ?? "",
      reviewDescription: data?.description,
      productName:
        ordersData[0]?.products[orderProductIndex - 1]?.title ??
        searchParams.get("productName"),
      title:
        ordersData[0]?.products[orderProductIndex - 1]?.title ??
        searchParams.get("productName"),
      reviewNo: orderProductIndex,
      mediaType: 3,
      contentType: 2,
      reviewerName:
        ordersData?.[0]?.customerName ??
        userData?.reviewerName ??
        data?.reviewerName,
      reviewerEmail:
        ordersData?.[0]?.customerEmail ??
        userData?.reviewerEmail ??
        data?.reviewerEmail,
      attributes: data?.attributes,
      images: [],
      isAuthorized: rcfvalue ? true : false,
      orderNo: orderNo,
    }

    console.log("submit123123123", body)
    if (!body.mediaUrl) {
    }
    console.log("body==>123123123", body)
    console.log("data", { data: encryptData(body) })
    setFiles([])

    try {
      // Create a new FormData object
      const formData = new FormData()
      if (files.length > 0) {
        body.contentType = 1
        // Append files to the FormData object
        files.forEach((file, index) => {
          formData.append(`files`, file)
        })
      }

      formData.append("data", encryptData(body))

      fetch(link + "addVReview", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "x-token": searchParams.get("id"),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("submit", fName, res)
          setCodeData(res?.review)

          setShowLoader(false)
          setShowDetail(true)
          if (res?.message === 2003) {
            handleContinueClick()

            setData({})
          } else alert("already added")

          if (orderProductIndex == ordersData?.[0]?.products.length)
            setShowLoaderSub(false)
        })

        .catch((error) => {
          setShowLoader(false)
          console.error("Error fetching data:", error)
        })
    } catch (error) {
      setShowLoader(false)
      console.error("Error fetching data:2", error)
    }
  }

  const sliderRef = useRef(null)
  const [sliderValue, setSliderValue] = useState(null)
  console.log("data==>", data)

  React.useEffect(() => {
    getPlan()
    try {
      let body = {
        productID: searchParams.get("productId"),
        merchantId: searchParams.get("merchantId"),
        productImage: searchParams.get("productImage"),
        item: {
          image: searchParams.get("productImage"),
          title: searchParams.get("productName"),
        },
      }
      console.log("body==>", body)
      setData({ ...body, attributes: [] })
      console.log("bodycheckStatus", body)
      console.log("body", encryptData(body))

      fetch(link + "getCollectReviews?merchantId=" + body?.merchantId)
        .then((response) => response.json())
        .then((res) => {
          console.log("getCollectReviews", res?.result)
          setSettings(res?.result?.newReviewForm)
          setCollectSettings(res?.result)
        })
        .catch((error) => {
          console.error("Error fetching data:", error)
        })

      getAttributes()
      setError(false)
    } catch (error) {
      setError(true)
    }
  }, [])

  const [brandSettingsData, setBrandSettingsData] = useState({})

  useEffect(() => {
    apiManager
      .get("getBrandSettings", null)
      .then((response) => {
        console.log("===>getBrandSettingsRFC", response.result)
        setBrandSettingsData(response.result)
      })
      .catch((error) => {
        console.error("Error:In getBrandSettings", error)
      })
  }, [])

  useEffect(() => {
    if (orderNo) {
      apiManager
        .get("getOrders", { orderNo: orderNo })
        .then((response) => {
          console.log(
            "===>getOrders",
            response.result,
            response.result?.[0]?.products[0]?.status
          )
          let data = response.result?.docs?.[0]?.products[0]?.status
            ? response.result?.docs?.[0]?.products?.filter(
                (item) => item.status === 1
              )
            : response.result?.docs?.[0]?.products
          console.log("===>getOrdersprod", data)
          if (response.result?.docs?.[0]) {
            response.result.docs[0].products = data
          }
          console.log("===>getOrdersprod", response.result.docs[0].products)
          setOrdersData(response.result.docs)
          // setOrderProductIndex(response.result.docs.length)
          
        })
        .catch((error) => {
          console.error("Error:In getOrders", error)
        })
    } else {
      setOrdersData([
        {
          products: [
            {
              title: productTitle,
              id: productId,
              image: productImage,
            },
          ],
        },
      ])
    }
  }, [])

  const getPlan = async () => {
    apiManager
      .get("getPlan")
      .then((res) => {
        console.log("getPlan232", res)
        apiManager?._setSubscription(res?.result)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  const getAttributes = async () => {
    apiManager
      .get("getAttributes")
      .then((res) => {
        console.log("setAttributes", res)
        setAttributes(res?.result)

        setPageLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }

  useEffect(() => {
    console.log(data, "thisIsDataClg")
    console.log(
      data?.review === undefined ||
        data?.description == "" ||
        data?.description === undefined,
      data?.description == "" || data?.description === undefined,
      "....thisIsDataClg"
    )
    if (
      data?.review === undefined ||
      data?.description == "" ||
      data?.description === undefined
      // ||
      // data?.attributes.length === 0
    ) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
  }, [data])

  const [orderProductIndex, setOrderProductIndex] = useState(1)
  const [submitReview, setSubmitReview] = useState(false)

  const [titleValue, setTitleValue] = useState("")
  const [desValue, setDesValue] = useState("")

  return (
    <>
      {pageLoader ? (
        <SectionLoader />
      ) : showLoaderSub ? (
        showLoader ? (
          <SectionLoader />
        ) : (
          <>
            <div className="rcf-product-main-container">
              <div
                className="rcf-product-div-heading"
                style={{
                  color: settings?.fontColor ?? "#000",
                  backgroundColor: settings?.backgroundColor ?? "#fff",
                  paddingBottom: offside === "true" ? "30px" : "30px",
                }}
              >
                <div className="slider-wrapper-rcf">
                  <>
                    <div>
                      <div
                        className="slide-content c-pointer"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "30px",
                        }}
                      >
                        {offside === "true" ? (
                          <div style={{ marginTop: "10px" }}></div>
                        ) : (
                          <div
                            className="cross-icon text-end"
                            onClick={() => {
                              setTimeout(
                                () =>
                                  window.parent.postMessage(
                                    { showRcf: true },
                                    "*"
                                  ),
                                200
                              )
                            }}
                          >
                            <CrossIcon />
                          </div>
                        )}
                        <div
                          className="rcf-main-heading"
                          style={{ marginTop: "-30px" }}
                        >
                          {brandSettingsData?.generalAppearance?.logo ? (
                            <img
                              src={getImageUrl(
                                brandSettingsData?.generalAppearance?.logo
                              )}
                              alt="Description"
                              style={{ width: "100px" }}
                            ></img>
                          ) : (
                            "Store logo"
                          )}
                        </div>
                        <hr style={{ margin: "0px" }} />
                        <NewRatePopup
                          onTitleChange={(e) => {
                            setData({ ...data, title: e.target.value })
                          }}
                          onReviewSelect={(rating) => {
                            console.log("rating", rating)

                            setData({ ...data, review: rating })
                          }}
                          data={data}
                          settings={settings}
                          brandSettingsData={brandSettingsData}
                          setStarCheck={setStarCheck}
                          orderProductIndex={orderProductIndex}
                          ordersData={ordersData}
                          starCheck={starCheck}
                          // item={item}
                        />

                        <hr style={{ margin: "0px" }} />
                        {files.length < 1 ? (
                          <label htmlFor="share-img1">
                            <div
                              className="rcf-picture-uploader"
                              style={{
                                color: settings?.fontColor ?? "#000",
                                "--rate-background":
                                  settings?.backgroundColor ?? "#fff",
                              }}
                            >
                              <div>
                                <img
                                  src={imgShareUpload}
                                  alt="imgShareUpload"
                                />
                              </div>
                              <div className="img-rfc-main-div-upload">
                                <div className="share-section">
                                  <div
                                    className="share-img"
                                    style={{
                                      color: settings?.fontColor ?? "#000",
                                    }}
                                  >
                                    <input
                                      style={{ display: "none" }}
                                      type="file"
                                      name="share"
                                      id="share-img1"
                                      accept="image/*,video/*"
                                      multiple
                                      onChange={(e) => {
                                        console.log("asjdhd", e.target.files)
                                        setFiles((prevFiles) => [
                                          ...prevFiles,
                                          ...e.target.files,
                                        ])
                                      }}
                                    />

                                    <span
                                      className="img-uploader-text"
                                      style={{
                                        color: settings?.fontColor ?? "#000",
                                      }}
                                    >
                                      {collectSettings
                                        ?.discountForPhotoAndVideoReview
                                        ?.typeOfDiscount === "no" ||
                                      !collectSettings
                                        ?.discountForPhotoAndVideoReview
                                        ?.typeOfDiscount
                                        ? "Share Photos/Videos & Help Others"
                                        : `Share Photos/Videos & Get ${
                                            collectSettings
                                              ?.discountForPhotoAndVideoReview
                                              ?.typeOfDiscountValue === "Custom"
                                              ? collectSettings
                                                  ?.discountForPhotoAndVideoReview
                                                  ?.typeOfDiscountValueCustom
                                              : collectSettings
                                                  ?.discountForPhotoAndVideoReview
                                                  ?.typeOfDiscountValue ?? "15 "
                                          }${
                                            collectSettings
                                              ?.discountForPhotoAndVideoReview
                                              ?.typeOfDiscount === "perc"
                                              ? "%"
                                              : storeCurrency ?? ""
                                          } off`}
                                    </span>
                                    <p
                                      className="upload-img-span-tag"
                                      // style={{
                                      //   color: settings?.fontColor ?? "#000",
                                      // }}
                                    >
                                      Upload jpg, jpeg, png, or mp4 (5 files,
                                      max. 10MB)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        ) : (
                          <div
                            className="rcf-picture-uploader"
                            style={{
                              color: settings?.fontColor ?? "#000",
                              "--rate-background":
                                settings?.backgroundColor ?? "#fff",
                            }}
                          >
                            <div>
                              <div className="new-rcf-plus-uploader">
                                <div className="share-img">
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    name="share"
                                    id="share-img"
                                    accept="image/*,video/*"
                                    multiple
                                    onChange={(e) => {
                                      console.log("asjdhd", e.target.files)
                                      setFiles((prevFiles) => [
                                        ...prevFiles,
                                        ...e.target.files,
                                      ])
                                    }}
                                  />
                                  <label htmlFor="share-img">
                                    <img src={PlusImage} alt="PlusImage" />
                                  </label>
                                </div>
                              </div>
                            </div>
                            {files.map((file, index) => {
                              console.log("here it", files)
                              console.log("here it", file)
                              return (
                                <div className="added-img" key={index}>
                                  <div className="img-info">
                                    {file.type.startsWith("video/") ? (
                                      <video
                                        src={URL.createObjectURL(file)}
                                        alt={`review ${index + 1}`}
                                        className="uplaod-content-rcf"
                                      />
                                    ) : (
                                      <div className="new-rcf-seen-uploader">
                                        <img
                                          className="rcf-new-image-upload uplaod-content-rcf"
                                          key={index}
                                          src={
                                            URL.createObjectURL(file) ??
                                            ReviewImage
                                          }
                                          alt={`review ${index + 1}`}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    onClick={() => {
                                      setFiles((prevFiles) => {
                                        return prevFiles.filter((prevFile) => {
                                          return prevFile.name !== file.name
                                        })
                                      })
                                    }}
                                  >
                                    <img
                                      src={Cancel}
                                      className={`seenImage-cancel-cross ${
                                        file.type.startsWith("video/")
                                          ? "video-cancel"
                                          : ""
                                      }`}
                                      alt="SeenImage"
                                    />
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )}

                        <NewTellUsStarter
                          errorAttribute={errorAttribute}
                          setErrorAttribute={setErrorAttribute}
                          requiredAttributes={requiredAttributes}
                          setRequiredAttributes={setRequiredAttributes}
                          attributeValue={attributeValue}
                          setAttribute={setAttribute}
                          settings={settings}
                          sliderRef={sliderRef}
                          sliderValue={sliderValue}
                          setSliderValue={setSliderValue}
                          setisTyped={setisTyped}
                          answer={answer}
                          setAnswer={setAnswer}
                          ordersData={ordersData}
                          orderProductIndex={orderProductIndex}
                          data={data}
                          setData={setData}
                          setFile={setFiles}
                          // ProductIndex={ProductIndex}
                          attributes={attributes}
                          onDescriptionChange={(e) => {
                            setData({
                              ...data,
                              description: e.target.value,
                            })
                            setDesValue(e.target.value)
                          }}
                          onTitleChange={(e) => {
                            setData({ ...data, title: e.target.value })
                            setTitleValue(e.target.value)
                          }}
                          onAttributeSelect={(attribute) => {
                            setData({ ...data, attributes: attribute })
                          }}
                          titleValue={titleValue}
                          desValue={desValue}
                          setReviewCheck={setReviewCheck}
                          starReview={starReview}
                          setAttributeAnswerValue={setAttributeAnswerValue}
                          setAttributeAnswerMultipleValue={
                            setAttributeAnswerMultipleValue
                          }
                        />
                      </div>
                    </div>
                  </>
                </div>

                {/* <Button onClick={() => swiper.slideNext()}>Hi there??</Button> */}

                <NewThatsAll
                  errorAttribute={errorAttribute}
                  setRequiredAttributes={setRequiredAttributes}
                  isTyped={isTyped}
                  onContinueClick={submit}
                  handleBack={handleBack}
                  attributes={attributes}
                  ordersData={ordersData}
                  orderProductIndex={orderProductIndex}
                  isDisable={isDisable}
                  settings={settings}
                  fName={fName}
                  setFName={setFName}
                  lName={lName}
                  setLName={setLName}
                  email={email}
                  setEmail={setEmail}
                  isTyped1={
                    orderNo
                      ? ordersData?.[0]?.customerName?.split(" ")?.[0]
                      : isTyped1
                  }
                  isTyped2={
                    orderNo
                      ? ordersData?.[0]?.customerName?.split(" ")?.[1]
                      : isTyped2
                  }
                  isTyped3={orderNo ? ordersData?.[0]?.customerEmail : isTyped3}
                  setIsTyped1={setIsTyped1}
                  setIsTyped2={setIsTyped2}
                  setIsTyped3={setIsTyped3}
                  data={data}
                  setAnswer={setAnswer}
                  starCheck={starCheck}
                  showDetail={showDetail}
                  starReview={starReview}
                  setOrderProductIndex={setOrderProductIndex}
                  files={files}
                  orderNo={orderNo}
                />
              </div>
            </div>
          </>
        )
      ) : codeData?.code ? (
        <OfferDiscount
          settings={settings}
          setOrderProductIndex={setOrderProductIndex}
          discount={codeData}
        />
      ) : (
        <NewReviewSubSimple
          settings={settings}
          setOrderProductIndex={setOrderProductIndex}
        />
      )}
    </>
  )
}
