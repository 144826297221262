import React, { useEffect, useState } from "react"
import "../../../styles/tellus-starter.scss"
import "../../../styles/tellus-starter.css"
import Slider from "rc-slider"
import { Input } from "reactstrap"
import { RATING_BOXES, checkAccess } from "../../../helper"

export default function NewTellUsStarter(props) {
  let {
    settings,
    attributeValue,
    setRequiredAttributes,
    setSliderValue,
    sliderValue,
    answer,
    setAnswer,
    errorAttribute,
    setErrorAttribute,
    ordersData,
    orderProductIndex,
    setAttribute,
    attributes,
    onTitleChange,
    setisTyped,
    onDescriptionChange,
    data,
    onAttributeSelect,
    setReviewCheck,
    setAttributeAnswerMultipleValue,
    item,
  } = props
  // const [attributeValue, setAttribute] = useState([])
  const [answerss, setAnswerss] = useState([])
  const [answerss2, setAnswerss2] = useState([...answerss])
  const [activeValue, setActiveValues] = useState(null)
  const [activeSingleValue, setActiveSingleValues] = useState(null)

  console.log(attributeValue, "ThisAattributeValue")

  const setAttributes = (attribute, answer, isMultiple = false) => {
    console.log("attributeValue", attribute)
    console.log(answer, "thisissetting")
    console.log(data.attributes, "thisissettingattributes")
    console.log(attributes, "this Is attributes side")
    console.log(attributeValue, "this Is attributeValue side")
    console.log(onDescriptionChange, "onDescriptionChangeonDescriptionChange")

    if (isMultiple) {
      if (
        attributeValue.findIndex(
          (item) => item.selectedAnswer.value === answer.value
        ) !== -1
      ) {
        attributeValue.splice(
          attributeValue.findIndex(
            (item) => item.selectedAnswer.value === answer.value
          ),
          1
        )
      } else {
        attributeValue.push({ ...attribute, selectedAnswer: answer })
      }
    } else {
      if (
        attributeValue.findIndex((item) => item._id === attribute._id) !== -1
      ) {
        attributeValue.splice(
          attributeValue.findIndex((item) => item._id === attribute._id),
          1
        )
      }
      attributeValue.push({ ...attribute, selectedAnswer: answer })
      if (attributeValue) {
        console.log(attributeValue, "selectedAnswerAnswer")
      }
      if (attributeValue.selectedAnswer && attributeValue.widgetType === 6) {
        setAttributeAnswerMultipleValue(false)
      }
    }
    setAttribute(attributeValue)
    setRequiredAttributes(false)
    onAttributeSelect(attributeValue)
    console.log("attributeValue--->", attributeValue)
  }

  console.log(settings, "settings?.newReviewForm?.reviewPageTitle?.text")
  console.log(
    data?.description?.length,
    "settings?.description?.textsettings?.description?.text"
  )

  console.log(data, "dataSidee")
  console.log(data?.attributes, "datattributes")
  console.log(data?.item?.title, "data?.item?.title")
  return (
    <>
      <div>
        <input
          className="input-field-rcf-review"
          name="title"
          type="text"
          style={{ color: settings?.fontColor ?? "#000" }}
          value={data?.title}
          placeholder={`${settings?.reviewPageTitle?.text ?? "Review Title"}`}
          onChange={(e) => {
            setisTyped(true)
            console.log("e.target.value====here", e.target.value == "")
            // if (e.target.value == "") setErrorAttribute(true)
            // else {
            //   setErrorAttribute(false)
            onTitleChange(e)
            // }
          }}
        ></input>
      </div>

      {/* <p>{starReview && "Please enter title"}</p> */}
      <div className="textarea-main-div">
        {ordersData ? (
          <textarea
            name="tellus"
            id="tellus-starter"
            cols="30"
            rows="10"
            className={`input-field-rcf-description ${
              errorAttribute ? "error" : ""
            }`}
            style={{ color: settings?.fontColor ?? "#000" }}
            value={data?.description}
            placeholder={
              settings?.description?.text
                ? settings?.description?.text?.replace("{product_name}", "") +
                  `${
                    settings?.description?.text?.includes("{product_name}") &&
                    item &&
                    item?.title
                      ? item?.title
                      : ``
                  }`
                : `Share Your Experience About ${
                    item
                      ? ordersData[0]?.products[orderProductIndex - 1]?.title
                      : ordersData[0]?.products[orderProductIndex - 1]?.title
                  }*`
            }
            onChange={(e) => {
              setisTyped(true)
              const inputValue = e.target.value
              if (inputValue.length > 500) {
                // Truncate the input if it exceeds 500 characters
                e.target.value = inputValue.slice(0, 500)
              } else {
                if (inputValue.length > 0) {
                  setReviewCheck(true)
                } else if (inputValue === "") {
                  setReviewCheck(false)
                }
                onDescriptionChange(e)
                setErrorAttribute(false)
              }
            }}
          ></textarea>
        ) : (
          <textarea
            name="tellus"
            id="tellus-starter"
            cols="30"
            rows="10"
            className={`input-field-rcf-description ${
              errorAttribute ? "error" : ""
            }`}
            style={{ color: settings?.fontColor ?? "#000" }}
            placeholder={
              settings?.description?.text
                ? settings?.description?.text?.replace("{product_name}", "") +
                  `${
                    settings?.description?.text?.includes("{product_name}") &&
                    data &&
                    data?.item?.title
                      ? data?.item?.title
                      : ``
                  }`
                : `Share Your Experience About ${
                    data ? data?.item?.title : data?.item?.title
                  }*`
            }
            onChange={(e) => {
              setisTyped(true)
              const inputValue = e.target.value
              if (inputValue.length > 500) {
                // Truncate the input if it exceeds 500 characters
                e.target.value = inputValue.slice(0, 500)
              } else {
                if (inputValue.length > 0) {
                  setReviewCheck(true)
                } else if (inputValue === "") {
                  setReviewCheck(false)
                }
                onDescriptionChange(e)
                setErrorAttribute(false)
              }
            }}
          ></textarea>
        )}
        <div className="description-rcf-text">
          <p className="text-field-count">
            {data?.description?.length ?? 0}
            /500
          </p>
        </div>
      </div>
      <div
        className=" rcf-tell-us-container"
        style={{
          color: settings?.fontColor ?? "#000",
          "--rate-background": settings?.SelectionColor ?? "#fff",
          "--select-hover-color": settings?.SelectionColor ?? "#0ABAAF",
          "--primary-color": settings?.SelectionColor ?? "#0ABAAF",
          "--select-background-color": settings?.SelectionColor ?? "#0ABAAF",
          "--select-font-color": settings?.SelectionFontColor ?? "#fff",
          "--font-color": settings?.fontColor ?? "#000",
        }}
      >
        <div
          className="tell-us-popup-content"
          style={{ width: "100% important" }}
        >
          <div
            className="textarea-details"
            style={{
              color: settings?.fontColor ?? "#000",
            }}
          >
            {/* {checkAccess(
          "attributeRatings", */}
            <div className="extra-details tell-us-new-rcf">
              {attributes?.map((attribute) => {
                //recomend product type 2
                if (attribute?.widgetType === 2) {
                  return (
                    <div className="health-concerns pt-4">
                      <p
                        className="description recommended-products-heading"
                        style={{
                          color: settings?.fontColor ?? "#000",
                        }}
                      >
                        {attribute?.question}
                        <span
                          style={{
                            color: settings?.fontColor ?? "#000",
                          }}
                        >
                          {attribute?.required && "*"}
                        </span>
                        <span className="single-choose-select">Choose 1</span>
                      </p>
                      <div
                        className="health-buttons my-rcf-selected-div"
                        style={{
                          color: settings?.fontColor ?? "#000",
                        }}
                      >
                        {[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]?.map((ans) => (
                          <button
                            className={`select-btn-rcf ${
                              ans.value === answer[attribute?.widgetType]
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              setAttributes(attribute, ans, false)
                              setAnswer({
                                ...answer,
                                [attribute?.widgetType]: ans.value,
                              })
                            }}
                          >
                            {ans.value}
                          </button>
                        ))}
                      </div>
                    </div>
                  )
                }
                //========================Number Selection =============
                else if (attribute?.widgetType === 3) {
                  return (
                    <div
                      className="effectiveness-helpful number-selection-main-div"
                      style={{ width: "100%" }}
                    >
                      <div className="effectiveness" style={{ width: "100%" }}>
                        <span
                          className="description recommended-products-heading new-rcf-main-heading"
                          style={{
                            color: settings?.fontColor ?? "#000",
                          }}
                        >
                          {attribute?.question}
                          <span
                            style={{
                              color: settings?.fontColor ?? "#000",
                              fontSize: "16px",
                            }}
                          >
                            {attribute?.required && "*"}
                          </span>
                        </span>
                        <span className="single-choose-select">Choose 1</span>
                        <div
                          className="effectiveness-no"
                          style={{
                            width: "100%",
                          }}
                        >
                          {RATING_BOXES?.map((item) => (
                            <span
                              onClick={() => {
                                console.log(
                                  "color======",
                                  settings?.SelectionFontColor ?? "#000",
                                  "backgroundColor======",
                                  item.value === answer[attribute?.widgetType]
                                    ? settings?.SelectionColor
                                    : "#fff",
                                  "border=====",
                                  item.value === answer[attribute?.widgetType]
                                    ? settings?.SelectionColor
                                    : ""
                                )
                                setAttributes(attribute, item, false)
                                setAnswer({
                                  ...answer,
                                  [attribute?.widgetType]: item.value,
                                })
                                setActiveSingleValues(item.value)
                              }}
                              // className={`select-choice-btn-rcf c-pointer effectiveness-item ${
                              //   answer[attribute?.widgetType] === item.value
                              //     ? "active"
                              //     : ""
                              // }`}
                              className={`select-choice-btn-rcf c-pointer effectiveness-item ${
                                activeSingleValue === item.value ? "active" : ""
                              }`}
                              style={{}}
                              key={item.value}
                            >
                              {item.label}
                            </span>
                          ))}
                        </div>
                        <div
                          className="rcf-heading-optins"
                          style={{
                            color: settings?.fontColor ?? "#000",
                          }}
                        >
                          <span className="rcf-heading-optins">
                            {attribute?.answers?.[0]?.label}
                          </span>
                          <span className="rcf-heading-optins">
                            {attribute?.answers?.[1]?.label}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                }
                //===================Option Bar WidgetType4=======
                else if (attribute?.widgetType === 4) {
                  return (
                    <div className="attribute-five attribute-five-new-rcf">
                      <div>
                        <p
                          className="description recommended-products-heading new-rcf-main-heading"
                          style={{
                            color: settings?.fontColor ?? "#000",
                          }}
                        >
                          {attribute?.question}
                          <span
                            style={{
                              color: settings?.fontColor ?? "#000",
                            }}
                          >
                            {attribute?.required && "*"}
                          </span>
                          <span className="single-choose-select">Choose 1</span>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Slider
                            className="slider-new-rcf"
                            min={0}
                            defaultValue={null}
                            marks={{
                              0: attribute?.answers?.[0]?.label,
                              50: attribute?.answers?.[1]?.label,
                              100: attribute?.answers?.[2]?.label,
                            }}
                            step={50}
                            included={false}
                            value={sliderValue}
                            // ref={sliderRef}
                            onChange={(value, label) => {
                              // Your onChange logic here
                              if (value === 0) {
                                value = attribute?.answers?.[0]?.value
                                label = attribute?.answers?.[0]?.label
                              }
                              if (value === 50) {
                                value = attribute?.answers?.[1]?.value
                                label = attribute?.answers?.[1]?.label
                              }
                              if (value === 100) {
                                value = attribute?.answers?.[2]?.value
                                label = attribute?.answers?.[2]?.label
                              }

                              console.log(value, "asdasdasd")

                              console.log("attribute---?", value)
                              setAttributes(
                                attribute,
                                { value: value, label: label },
                                false
                              )
                              setSliderValue(value)
                            }}
                            style={{
                              background:
                                attribute?.value ===
                                attribute?.answers?.[1]?.value
                                  ? "#ccc"
                                  : undefined,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
                //=================center-Option_bar==============
                else if (attribute?.widgetType === 5) {
                  return (
                    <div
                      className="attribute-four"
                      style={{
                        padding: "0 0 30px 0px",
                      }}
                    >
                      <div>
                        <p
                          className="description recommended-products-heading new-rcf-main-heading"
                          style={{ color: settings?.fontColor ?? "#000" }}
                        >
                          <span
                            style={{
                              color: settings?.fontColor ?? "#000",
                            }}
                          ></span>
                          {attribute?.question}
                          {attribute?.required && "*"}
                          <span className="single-choose-select">Choose 1</span>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Slider
                            className="center-bar-new-rcf"
                            style={{ width: "96% !important" }}
                            min={0}
                            defaultValue={null}
                            marks={{
                              0: attribute?.answers?.[0]?.label,
                              50: attribute?.answers?.[1]?.label,
                              100: attribute?.answers?.[2]?.label,
                            }}
                            step={50}
                            included={false}
                            // value={sliderValue}
                            onChange={(value, label) => {
                              if (value === 0) {
                                value = attribute?.answers?.[0]?.value
                                label = attribute?.answers?.[0]?.label
                              }
                              if (value === 50) {
                                value = attribute?.answers?.[1]?.value
                                label = attribute?.answers?.[1]?.label
                              }
                              if (value === 100) {
                                value = attribute?.answers?.[2]?.value
                                label = attribute?.answers?.[2]?.label
                              }
                              setAttributes(
                                attribute,
                                {
                                  value: value,
                                  label: label,
                                },
                                false
                              )
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
                // =====================Mutiple Selection ====================
                else if (attribute?.widgetType === 6) {
                  return (
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p
                          className="recommended-products-heading new-rcf-main-heading"
                          style={{
                            color: settings?.fontColor ?? "#000",
                          }}
                        >
                          {" "}
                          {attribute?.question}
                          <span>{attribute?.required && "*"}</span>
                        </p>
                        <div style={{ paddingTop: "8px" }}>
                          <span className="single-choose-select">
                            Choose 1 or more
                          </span>
                        </div>
                      </div>
                      <div className="rcf-div-multi-option">
                        {attribute?.answers?.map((answers, index) => (
                          <button
                            key={index}
                            className={`select-multi-btn-rcf ${
                              answerss2?.includes(index) ? "active" : ""
                            }`}
                            onClick={() => {
                              setAttributes(attribute, answers, true)
                              if (answerss2?.includes(index)) {
                                answerss2.splice(answerss.indexOf(index), 1)
                              } else answerss2.push(index)
                              setAnswerss(answerss2)
                              setAnswer({
                                ...answer,
                                [attribute?.widgetType]: answers.value,
                              })
                              setAnswerss("")
                              console.log(
                                "indexx==",
                                answerss2?.includes(index)
                              )
                              console.log("multi=>", answerss2)
                            }}
                          >
                            {answers.value}
                          </button>
                        ))}
                      </div>
                    </div>
                  )
                } else if (attribute?.widgetType === 7) {
                  return (
                    <div
                      className="gender"
                      style={{
                        color: settings?.fontColor ?? "#000",
                      }}
                    >
                      <label
                        className="recommended-products-heading new-rcf-main-heading"
                        htmlFor="dropdown"
                      >
                        <span
                          style={{
                            color: settings?.fontColor ?? "#000",
                          }}
                        ></span>
                        {attribute?.question}
                        {attribute?.required && "*"}
                      </label>
                      <span className="single-choose-select">Choose 1</span>
                      <Input
                        className="dropDown-select"
                        type="select"
                        name="drop"
                        id="dropdowm"
                        style={{ color: settings?.fontColor ?? "#000" }}
                        onChange={(e) => {
                          setAttributes(
                            attribute,
                            {
                              value: e.target.value,
                              label: e.target.value,
                            },
                            false
                          )
                        }}
                      >
                        <option
                          disabled
                          value=""
                          selected
                          className="dropdown-selected-new-rcf"
                        >
                          Choose Option
                        </option>
                        {attribute?.answers?.map((answer) => (
                          <option value={answer.value}>{answer.label}</option>
                        ))}
                      </Input>
                    </div>
                  )
                }
                ///=========================single select======================
                else if (attribute?.widgetType === 8) {
                  return (
                    <div>
                      <div>
                        <label
                          className="recommended-products-heading new-rcf-main-heading"
                          style={{
                            color: settings?.fontColor ?? "#000",
                          }}
                        >
                          {attribute?.question}
                          <span
                            style={{
                              color: settings?.fontColor ?? "#000",
                            }}
                          >
                            {attribute?.required && "*"}
                          </span>
                        </label>
                        <span className="single-choose-select">Choose 1</span>
                      </div>
                      <div
                        className="rcf-div-multi-option"
                        style={{
                          color: settings?.fontColor ?? "#000",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {attribute?.answers?.map((answer) => (
                          <div className="d-flex">
                            <div style={{ paddingTop: "1px" }}>
                              <input
                                type="radio"
                                id={`radio-${answer.value}`}
                                className={`input-radio-btn select-multi-btn-rcf ${
                                  activeValue === answer.value ? "active" : ""
                                }`}
                                name="radio1"
                                value={answer?.value}
                                checked={activeValue === answer.value}
                                onChange={() => {
                                  console.log("att==>", answer, attribute)
                                  setAttributes(
                                    attribute,
                                    {
                                      value: answer?.value,
                                      label: answer?.label,
                                    },
                                    false
                                  )
                                  setActiveValues(answer.value)
                                }}
                              />
                            </div>
                            <label
                              style={{ paddingLeft: "20px" }}
                              htmlFor={`radio-${answer.value}`}
                            >
                              {answer?.value}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                } else return <div></div>
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
